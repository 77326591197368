import { getSchool } from "api/apiCall";
import { GETSCHOOL, PARENTRESULTPIN } from "api/apiUrl";
import { queryKeys } from "api/queryKey";
import { ToastContext } from "App";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import { postRequest } from "../api/apiCall";
import jwt from "jsonwebtoken";

export default function CheckResult() {
  const { showAlert } = React.useContext(ToastContext);
  const params: { slug: any } = useParams();
  const history = useHistory();
  const school = params?.slug;
  const { data } = useQuery(
    [queryKeys.getSchool, school],
    async () => await getSchool({ url: GETSCHOOL(school) }),
    {
      retry: 2,
      enabled: !!school,
    }
  );
  // console.log(school)
  const [schoolData, setSchoolData] = React.useState(data?.data);
  React.useEffect(() => {
    setSchoolData(data?.data);
  }, [data?.data]);
  const [state, setState] = React.useState({
    last_name: "",
    pin: "",
  });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const { mutate } = useMutation(postRequest, {
    async onSuccess(data) {
      const student = {
        did_first_term: data?.data?.did_first_term,
        did_second_term: data?.data?.did_second_term,
        grade: data?.data?.grade,
        header_image: data?.data?.header_image,
        next_term_begin_date: data?.data?.next_term_begin_date,
        overall_remark: data?.data?.overall_remark,
        percentage: data?.data?.percentage,
        principal_remark: data?.data?.principal_remark,
        results: data?.data?.results,
        session: data?.data?.session,
        student: data?.data?.student,
        teacher_remark: data?.data?.teacher_remark,
        school_owner_title: data?.data?.school_owner_title,
        total_obtainable: data?.data?.total_obtainable,
        total_obtained: data?.data?.total_obtained,
      };
      console.log(student);
      showAlert({
        message: data?.message,
        severity: "success",
      });
      const token = await jwt.sign(student, "results");
      if (token) {
        history.push(`/student_result-${token}`, `/student_result-${token}`);
      }
    },
  });
  const handleSubmit = (e: any) => {
    e.preventDefault();
    mutate({
      url: PARENTRESULTPIN(schoolData?.uid),
      data: state,
    });
  };
  return (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <div className="">
        <img src={schoolData?.logo} alt="" />
      </div>
      <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-900">
        {schoolData?.name}
      </h2>
      <div className="mt-2 text-4xl font-extrabold">Result Checker</div>
      <div className="flex flex-col items-center justify-center max-w-xl w-full">
        <form className="w-full px-6 mx-3 mb-3 mt-9" onSubmit={handleSubmit}>
          {[
            {
              label: "Student's Surname",
              id: "last_name",
              value: state.last_name,
            },
            { label: "Scratch Card Pin", id: "pin", value: state.pin },
          ].map((inp, i) => (
            <div className="my-2">
              <label htmlFor={inp.id}>{inp.label}</label>
              <input
                id={inp.id}
                name={inp.id}
                type="text"
                onChange={handleChange}
                value={inp.value}
                autoComplete={inp.id}
                required
                className="relative block w-full px-3 py-2 mb-1 text-gray-900 placeholder-gray-500 border border-gray-300 appearance-none rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                placeholder={`Enter ${inp.label}`}
              />
            </div>
          ))}
          <div>
            <button
              type="submit"
              className="relative flex justify-center w-full py-2 mt-5 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md cursor-pointer group hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Check Result
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
