export const StaffList = [
  {
    full_name: "Alfreds Futterkiste	Maria",
    gender: "Male",
    religion: "Christianity",
    email: "test123@gmail.com",
    mobile_number: "09078044747",
    class: "Creche",
    date_of_birth: "01-09-2001",
    residential_address: "Obere Str. 57	Berlin",
    id: 1
  },
  {
    full_name: "Ana Trujillo Emparedados",
    gender: "Female",
    religion: "Islam",
    email: "test123@gmail.com",
    mobile_number: "09078044747",
    class: "Kindergaten 1",
    date_of_birth: "01-09-2001",
    residential_address: "Obere Str. 57	Berlin",
    id: 2
  },
  {
    full_name: "Antonio Moreno Taquería",
    gender: "Male",
    religion: "Christianity",
    email: "test123@gmail.com",
    mobile_number: "09078044747",
    class: "Kindergaten 2",
    date_of_birth: "01-09-2001",
    residential_address: "Obere Str. 57	Berlin",
    id: 3
  },
  {
    full_name: "Horn	Thomas Hardy",
    gender: "Female",
    religion: "Islam",
    email: "test123@gmail.com",
    mobile_number: "09078044747",
    class: "Nursery 1",
    date_of_birth: "01-09-2001",
    residential_address: "Obere Str. 57	Berlin",
    id: 4
  },
  {
    full_name: "Christina Berglund	Berguvsvägen",
    gender: "Male",
    religion: "Christianity",
    email: "test123@gmail.com",
    mobile_number: "09078044747",
    class: "Nursery 2",
    date_of_birth: "01-09-2001",
    residential_address: "Obere Str. 57	Berlin",
    id: 5
  },
  {
    full_name: "Delikatessen	Hanna Moos",
    gender: "Female",
    religion: "Islam",
    email: "test123@gmail.com",
    mobile_number: "09078044747",
    class: "Basic 1",
    date_of_birth: "01-09-2001",
    residential_address: "Obere Str. 57	Berlin",
    id: 6
  },
  {
    full_name: "fils	Frédérique Citeaux",
    gender: "Male",
    religion: "Christianity",
    email: "test123@gmail.com",
    mobile_number: "09078044747",
    class: "Basic 2",
    date_of_birth: "01-09-2001",
    residential_address: "Obere Str. 57	Berlin",
    id: 7
  },
  {
    full_name: "preparadas	Martín Sommer",
    gender: "Female",
    religion: "Islam",
    email: "test123@gmail.com",
    mobile_number: "09078044747",
    class: "Basic 3",
    date_of_birth: "01-09-2001",
    residential_address: "Obere Str. 57	Berlin",
    id: 8
  },
  {
    full_name: "Laurence Lebihans",
    gender: "Male",
    religion: "Christianity",
    email: "test123@gmail.com",
    mobile_number: "09078044747",
    class: "Basic 4",
    date_of_birth: "01-09-2001",
    residential_address: "Obere Str. 57	Berlin",
    id: 9
  },
  {
    full_name: "Marketse	Elizabeth Lincoln",
    gender: "Male",
    religion: "Islam",
    email: "test123@gmail.com",
    mobile_number: "09078044747",
    class: "Basic 5",
    date_of_birth: "01-09-2001",
    residential_address: "Obere Str. 57	Berlin",
    id: 10
  },
  {
    full_name: "Victoria Ashworth	Fauntleroy",
    gender: "Female",
    religion: "Christianity",
    email: "test123@gmail.com",
    mobile_number: "09078044747",
    class: "Basic 6",
    date_of_birth: "01-09-2001",
    residential_address: "Obere Str. 57	Berlin",
    id: 11
  },
  {
    full_name: "llevar	Patricio Simpson",
    gender: "Female",
    religion: "Islam",
    email: "test123@gmail.com",
    mobile_number: "09078044747",
    class: "JSS 1",
    date_of_birth: "01-09-2001",
    residential_address: "Obere Str. 57	Berlin",
    id: 12
  },
  {
    full_name: "Francisco Chang	Sierras",
    gender: "Male",
    religion: "Christianity",
    email: "test123@gmail.com",
    mobile_number: "09078044747",
    class: "JSS 2",
    date_of_birth: "01-09-2001",
    residential_address: "Obere Str. 57	Berlin",
    id: 13
  },
  {
    full_name: "Yang Wang	Hauptstr",
    gender: "Female",
    religion: "Islam",
    email: "test123@gmail.com",
    mobile_number: "09078044747",
    class: "JSS 3",
    date_of_birth: "01-09-2001",
    residential_address: "Obere Str. 57	Berlin",
    id: 14
  },
  {
    full_name: "Mineiro	Pedro Afonso",
    gender: "Male",
    religion: "Christianity",
    email: "test123@gmail.com",
    mobile_number: "09078044747",
    class: "SSS 1",
    date_of_birth: "01-09-2001",
    residential_address: "Obere Str. 57	Berlin",
    id: 15
  },
  {
    full_name: "Elizabeth Brown	Berkeley",
    gender: "Female",
    religion: "Islam",
    email: "test123@gmail.com",
    mobile_number: "09078044747",
    class: "SSS 2",
    date_of_birth: "01-09-2001",
    residential_address: "Obere Str. 57	Berlin",
    id: 16
  },
  {
    full_name: "Drachenblut Delikatessend	Sven",
    gender: "Male",
    religion: "Christianity",
    email: "test123@gmail.com",
    mobile_number: "09078044747",
    class: "SSS 3",
    date_of_birth: "01-09-2001",
    residential_address: "Obere Str. 57	Berlin",
    id: 17
  },
];
