export const Courses = [
  "English",
  "Mathematics",
  "Civic",
  "Yoruba",
  "Quantitative",
  "Hausa",
  "Agric",
  "Igbo",
  "Verbal",
  "Vocational",
  "P.H.E",
  "IRK",
  "Computer",
  "CRK",
  "Social Studies",
  "Home Economics",
  "Basic Science",
  "Handwriting",
  "Fine Art",
  "Literature In English",
  "C.C.A",
  "Business Studies",
  "Music",
  "Physics",
  "Chemistry",
  "Biology",
  "Commerce",
  "Account",
  "Government",
  "Book Keeping",
  "Economics",
  "Further Mathematics",
  "Phonics/Diction",
  "Data Proccessing",
  "French"
]
