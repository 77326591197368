import { getRequest } from 'api/apiCall';
import { RESULTPIN } from 'api/apiUrl';
import { queryKeys } from 'api/queryKey';
import jwtDecode from 'jwt-decode';
import React from 'react'
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid'

export default function ClassPin() {
    const params: { id } = useParams()
    // console.log(params, uuidv4())
    const easysch_token: { school_uid: any } = jwtDecode(
      localStorage?.easysch_token
    );
    const { schoolSlug: school } = localStorage;
  
    const { data: classPins } = useQuery(
      [queryKeys.getClassPins, easysch_token?.school_uid, params.id],
      async () => await getRequest({ url: RESULTPIN(easysch_token?.school_uid, params.id) }),
      {
        retry: 2,
        enabled: !!(easysch_token?.school_uid&&params.id),
      }
    );
    const [pins, setPins] = React.useState(classPins?.data);
    React.useEffect(() => {
      setPins(classPins?.data);
    }, [classPins?.data]);
    console.log(pins)
  return (
    <div className="min-h-screen w-screen p-5">
      <div className="w-full h-full grid grid-cols-3 rounded">
        {
          pins?.map((pin, i)=>(
            <div className="p-4 flex items-center flex-col justify-center border-2 border-gray-400 rounded" key={i}>
          <div className="">{pin.student.full_name}</div>
          <div className="text-2xl font-extrabold">Pin: {pin.pin}</div>
          {/* <div className="text-2xl font-extrabold">Pin: {pin.pin.slice(0, 12)}</div> */}
          <div className="">To check result go to <span>{window.location.origin}/{school}-check_result</span></div>
        </div>
          ))
        }
      </div>
    </div>
  )
}
