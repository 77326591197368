export const StudentList = [
  {
    first_name: "Musa",
    last_name: "Jubril",
    middle_name: "",
    guardian_name: "Safiyyah",
    gender: "Male",
    religion: "Islam",
    email: "jewbreel1@gmail.com",
    mobile_number: "09078044747",
    state: "Kogi",
    lga: "Ankpa",
    class: "Creche",
    date_of_birth: "01-09-2001",
    residential_address: "Block 6, Flat 11, Arigbanla Millenium Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 1
  },
  {
    first_name: "Habeebah",
    last_name: "Lawal",
    middle_name: "Oyindamola",
    guardian_name: "Bolanle mudashir",
    gender: "Female",
    religion: "Islam",
    email: "damolabee5@gmail.com",
    mobile_number: "09078044747",
    state: "Kwara",
    lga: "Ilorin",
    class: "Creche",
    date_of_birth: "02-09-2001",
    residential_address: "Green roof Estate Phase 2 Ikorodu",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 2
  },
  {
    first_name: "Morufah",
    last_name: "Adebayo",
    middle_name: "Titilayo",
    guardian_name: "Adebayo",
    gender: "Female",
    religion: "Islam",
    email: "adebayomorufah@gmail.com",
    mobile_number: "09078044747",
    state: "Ogun",
    lga: "Abeokuta",
    class: "Creche",
    date_of_birth: "03-09-2001",
    residential_address: "Ayobo Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 3
  },
  {
    first_name: "Musa",
    last_name: "Jubril",
    middle_name: "",
    guardian_name: "Safiyyah",
    gender: "Male",
    religion: "Islam",
    email: "jewbreel1@gmail.com",
    mobile_number: "09078044747",
    state: "Kogi",
    lga: "Ankpa",
    class: "Kindergaten 1",
    date_of_birth: "04-09-2001",
    residential_address: "Block 6, Flat 11, Arigbanla Millenium Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 4
  },
  {
    first_name: "Habeebah",
    last_name: "Lawal",
    middle_name: "Oyindamola",
    guardian_name: "Bolanle mudashir",
    gender: "Female",
    religion: "Islam",
    email: "damolabee5@gmail.com",
    mobile_number: "09078044747",
    state: "Kwara",
    lga: "Ilorin",
    class: "Kindergaten 1",
    date_of_birth: "05-09-2001",
    residential_address: "Green roof Estate Phase 2 Ikorodu",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 5
  },
  {
    first_name: "Morufah",
    last_name: "Adebayo",
    middle_name: "Titilayo",
    guardian_name: "Adebayo",
    gender: "Female",
    religion: "Islam",
    email: "adebayomorufah@gmail.com",
    mobile_number: "09078044747",
    state: "Ogun",
    lga: "Abeokuta",
    class: "Kindergaten 1",
    date_of_birth: "06-09-2001",
    residential_address: "Ayobo Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 6
  },
  {
    first_name: "Musa",
    last_name: "Jubril",
    middle_name: "",
    guardian_name: "Safiyyah",
    gender: "Male",
    religion: "Islam",
    email: "jewbreel1@gmail.com",
    mobile_number: "09078044747",
    state: "Kogi",
    lga: "Ankpa",
    class: "Kindergaten 2",
    date_of_birth: "07-09-2001",
    residential_address: "Block 6, Flat 11, Arigbanla Millenium Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 7
  },
  {
    first_name: "Habeebah",
    last_name: "Lawal",
    middle_name: "Oyindamola",
    guardian_name: "Bolanle mudashir",
    gender: "Female",
    religion: "Islam",
    email: "damolabee5@gmail.com",
    mobile_number: "09078044747",
    state: "Kwara",
    lga: "Ilorin",
    class: "Kindergaten 2",
    date_of_birth: "08-09-2001",
    residential_address: "Green roof Estate Phase 2 Ikorodu",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 8
  },
  {
    first_name: "Morufah",
    last_name: "Adebayo",
    middle_name: "Titilayo",
    guardian_name: "Adebayo",
    gender: "Female",
    religion: "Islam",
    email: "adebayomorufah@gmail.com",
    mobile_number: "09078044747",
    state: "Ogun",
    lga: "Abeokuta",
    class: "Kindergaten 2",
    date_of_birth: "09-09-2001",
    residential_address: "Ayobo Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 9
  },
  {
    first_name: "Musa",
    last_name: "Jubril",
    middle_name: "",
    guardian_name: "Safiyyah",
    gender: "Male",
    religion: "Islam",
    email: "jewbreel1@gmail.com",
    mobile_number: "09078044747",
    state: "Kogi",
    lga: "Ankpa",
    class: "Nursery 1",
    date_of_birth: "10-09-2001",
    residential_address: "Block 6, Flat 11, Arigbanla Millenium Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 10
  },
  {
    first_name: "Habeebah",
    last_name: "Lawal",
    middle_name: "Oyindamola",
    guardian_name: "Bolanle mudashir",
    gender: "Female",
    religion: "Islam",
    email: "damolabee5@gmail.com",
    mobile_number: "09078044747",
    state: "Kwara",
    lga: "Ilorin",
    class: "Nursery 1",
    date_of_birth: "11-09-2001",
    residential_address: "Green roof Estate Phase 2 Ikorodu",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 11
  },
  {
    first_name: "Morufah",
    last_name: "Adebayo",
    middle_name: "Titilayo",
    guardian_name: "Adebayo",
    gender: "Female",
    religion: "Islam",
    email: "adebayomorufah@gmail.com",
    mobile_number: "09078044747",
    state: "Ogun",
    lga: "Abeokuta",
    class: "Nursery 1",
    date_of_birth: "12-09-2001",
    residential_address: "Ayobo Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 12
  },
  {
    first_name: "Musa",
    last_name: "Jubril",
    middle_name: "",
    guardian_name: "Safiyyah",
    gender: "Male",
    religion: "Islam",
    email: "jewbreel1@gmail.com",
    mobile_number: "09078044747",
    state: "Kogi",
    lga: "Ankpa",
    class: "Nursery 2",
    date_of_birth: "01-09-2001",
    residential_address: "Block 6, Flat 11, Arigbanla Millenium Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 13
  },
  {
    first_name: "Habeebah",
    last_name: "Lawal",
    middle_name: "Oyindamola",
    guardian_name: "Bolanle mudashir",
    gender: "Female",
    religion: "Islam",
    email: "damolabee5@gmail.com",
    mobile_number: "09078044747",
    state: "Kwara",
    lga: "Ilorin",
    class: "Nursery 2",
    date_of_birth: "02-09-2001",
    residential_address: "Green roof Estate Phase 2 Ikorodu",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 14
  },
  {
    first_name: "Morufah",
    last_name: "Adebayo",
    middle_name: "Titilayo",
    guardian_name: "Adebayo",
    gender: "Female",
    religion: "Islam",
    email: "adebayomorufah@gmail.com",
    mobile_number: "09078044747",
    state: "Ogun",
    lga: "Abeokuta",
    class: "Nursery 2",
    date_of_birth: "03-09-2001",
    residential_address: "Ayobo Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 15
  },
  {
    first_name: "Musa",
    last_name: "Jubril",
    middle_name: "",
    guardian_name: "Safiyyah",
    gender: "Male",
    religion: "Islam",
    email: "jewbreel1@gmail.com",
    mobile_number: "09078044747",
    state: "Kogi",
    lga: "Ankpa",
    class: "Basic 1",
    date_of_birth: "04-09-2001",
    residential_address: "Block 6, Flat 11, Arigbanla Millenium Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 16
  },
  {
    first_name: "Habeebah",
    last_name: "Lawal",
    middle_name: "Oyindamola",
    guardian_name: "Bolanle mudashir",
    gender: "Female",
    religion: "Islam",
    email: "damolabee5@gmail.com",
    mobile_number: "09078044747",
    state: "Kwara",
    lga: "Ilorin",
    class: "Basic 1",
    date_of_birth: "05-09-2001",
    residential_address: "Green roof Estate Phase 2 Ikorodu",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 17
  },
  {
    first_name: "Morufah",
    last_name: "Adebayo",
    middle_name: "Titilayo",
    guardian_name: "Adebayo",
    gender: "Female",
    religion: "Islam",
    email: "adebayomorufah@gmail.com",
    mobile_number: "09078044747",
    state: "Ogun",
    lga: "Abeokuta",
    class: "Basic 1",
    date_of_birth: "06-09-2001",
    residential_address: "Ayobo Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 18
  },
  {
    first_name: "Musa",
    last_name: "Jubril",
    middle_name: "",
    guardian_name: "Safiyyah",
    gender: "Male",
    religion: "Islam",
    email: "jewbreel1@gmail.com",
    mobile_number: "09078044747",
    state: "Kogi",
    lga: "Ankpa",
    class: "Basic 2",
    date_of_birth: "07-09-2001",
    residential_address: "Block 6, Flat 11, Arigbanla Millenium Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 19
  },
  {
    first_name: "Habeebah",
    last_name: "Lawal",
    middle_name: "Oyindamola",
    guardian_name: "Bolanle mudashir",
    gender: "Female",
    religion: "Islam",
    email: "damolabee5@gmail.com",
    mobile_number: "09078044747",
    state: "Kwara",
    lga: "Ilorin",
    class: "Basic 2",
    date_of_birth: "08-09-2001",
    residential_address: "Green roof Estate Phase 2 Ikorodu",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 20
  },
  {
    first_name: "Morufah",
    last_name: "Adebayo",
    middle_name: "Titilayo",
    guardian_name: "Adebayo",
    gender: "Female",
    religion: "Islam",
    email: "adebayomorufah@gmail.com",
    mobile_number: "09078044747",
    state: "Ogun",
    lga: "Abeokuta",
    class: "Basic 2",
    date_of_birth: "09-09-2001",
    residential_address: "Ayobo Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 21
  },
  {
    first_name: "Musa",
    last_name: "Jubril",
    middle_name: "",
    guardian_name: "Safiyyah",
    gender: "Male",
    religion: "Islam",
    email: "jewbreel1@gmail.com",
    mobile_number: "09078044747",
    state: "Kogi",
    lga: "Ankpa",
    class: "Basic 3",
    date_of_birth: "10-09-2001",
    residential_address: "Block 6, Flat 11, Arigbanla Millenium Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 22
  },
  {
    first_name: "Habeebah",
    last_name: "Lawal",
    middle_name: "Oyindamola",
    guardian_name: "Bolanle mudashir",
    gender: "Female",
    religion: "Islam",
    email: "damolabee5@gmail.com",
    mobile_number: "09078044747",
    state: "Kwara",
    lga: "Ilorin",
    class: "Basic 3",
    date_of_birth: "11-09-2001",
    residential_address: "Green roof Estate Phase 2 Ikorodu",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 23
  },
  {
    first_name: "Morufah",
    last_name: "Adebayo",
    middle_name: "Titilayo",
    guardian_name: "Adebayo",
    gender: "Female",
    religion: "Islam",
    email: "adebayomorufah@gmail.com",
    mobile_number: "09078044747",
    state: "Ogun",
    lga: "Abeokuta",
    class: "Basic 3",
    date_of_birth: "12-09-2001",
    residential_address: "Ayobo Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 24
  },
  {
    first_name: "Musa",
    last_name: "Jubril",
    middle_name: "",
    guardian_name: "Safiyyah",
    gender: "Male",
    religion: "Islam",
    email: "jewbreel1@gmail.com",
    mobile_number: "09078044747",
    state: "Kogi",
    lga: "Ankpa",
    class: "Basic 4",
    date_of_birth: "01-09-2001",
    residential_address: "Block 6, Flat 11, Arigbanla Millenium Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 25
  },
  {
    first_name: "Habeebah",
    last_name: "Lawal",
    middle_name: "Oyindamola",
    guardian_name: "Bolanle mudashir",
    gender: "Female",
    religion: "Islam",
    email: "damolabee5@gmail.com",
    mobile_number: "09078044747",
    state: "Kwara",
    lga: "Ilorin",
    class: "Basic 4",
    date_of_birth: "02-09-2001",
    residential_address: "Green roof Estate Phase 2 Ikorodu",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 26
  },
  {
    first_name: "Morufah",
    last_name: "Adebayo",
    middle_name: "Titilayo",
    guardian_name: "Adebayo",
    gender: "Female",
    religion: "Islam",
    email: "adebayomorufah@gmail.com",
    mobile_number: "09078044747",
    state: "Ogun",
    lga: "Abeokuta",
    class: "Basic 4",
    date_of_birth: "03-09-2001",
    residential_address: "Ayobo Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 27
  },
  {
    first_name: "Musa",
    last_name: "Jubril",
    middle_name: "",
    guardian_name: "Safiyyah",
    gender: "Male",
    religion: "Islam",
    email: "jewbreel1@gmail.com",
    mobile_number: "09078044747",
    state: "Kogi",
    lga: "Ankpa",
    class: "Basic 5",
    date_of_birth: "04-09-2001",
    residential_address: "Block 6, Flat 11, Arigbanla Millenium Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 28
  },
  {
    first_name: "Habeebah",
    last_name: "Lawal",
    middle_name: "Oyindamola",
    guardian_name: "Bolanle mudashir",
    gender: "Female",
    religion: "Islam",
    email: "damolabee5@gmail.com",
    mobile_number: "09078044747",
    state: "Kwara",
    lga: "Ilorin",
    class: "Basic 5",
    date_of_birth: "05-09-2001",
    residential_address: "Green roof Estate Phase 2 Ikorodu",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 29
  },
  {
    first_name: "Morufah",
    last_name: "Adebayo",
    middle_name: "Titilayo",
    guardian_name: "Adebayo",
    gender: "Female",
    religion: "Islam",
    email: "adebayomorufah@gmail.com",
    mobile_number: "09078044747",
    state: "Ogun",
    lga: "Abeokuta",
    class: "Basic 5",
    date_of_birth: "06-09-2001",
    residential_address: "Ayobo Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 30
  },
  {
    first_name: "Musa",
    last_name: "Jubril",
    middle_name: "",
    guardian_name: "Safiyyah",
    gender: "Male",
    religion: "Islam",
    email: "jewbreel1@gmail.com",
    mobile_number: "09078044747",
    state: "Kogi",
    lga: "Ankpa",
    class: "Basic 6",
    date_of_birth: "07-09-2001",
    residential_address: "Block 6, Flat 11, Arigbanla Millenium Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 31
  },
  {
    first_name: "Habeebah",
    last_name: "Lawal",
    middle_name: "Oyindamola",
    guardian_name: "Bolanle mudashir",
    gender: "Female",
    religion: "Islam",
    email: "damolabee5@gmail.com",
    mobile_number: "09078044747",
    state: "Kwara",
    lga: "Ilorin",
    class: "Basic 6",
    date_of_birth: "08-09-2001",
    residential_address: "Green roof Estate Phase 2 Ikorodu",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 32
  },
  {
    first_name: "Morufah",
    last_name: "Adebayo",
    middle_name: "Titilayo",
    guardian_name: "Adebayo",
    gender: "Female",
    religion: "Islam",
    email: "adebayomorufah@gmail.com",
    mobile_number: "09078044747",
    state: "Ogun",
    lga: "Abeokuta",
    class: "Basic 6",
    date_of_birth: "09-09-2001",
    residential_address: "Ayobo Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 33
  },
  {
    first_name: "Musa",
    last_name: "Jubril",
    middle_name: "",
    guardian_name: "Safiyyah",
    gender: "Male",
    religion: "Islam",
    email: "jewbreel1@gmail.com",
    mobile_number: "09078044747",
    state: "Kogi",
    lga: "Ankpa",
    class: "JSS 1",
    date_of_birth: "10-09-2001",
    residential_address: "Block 6, Flat 11, Arigbanla Millenium Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 34
  },
  {
    first_name: "Habeebah",
    last_name: "Lawal",
    middle_name: "Oyindamola",
    guardian_name: "Bolanle mudashir",
    gender: "Female",
    religion: "Islam",
    email: "damolabee5@gmail.com",
    mobile_number: "09078044747",
    state: "Kwara",
    lga: "Ilorin",
    class: "JSS 1",
    date_of_birth: "11-09-2001",
    residential_address: "Green roof Estate Phase 2 Ikorodu",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 35
  },
  {
    first_name: "Morufah",
    last_name: "Adebayo",
    middle_name: "Titilayo",
    guardian_name: "Adebayo",
    gender: "Female",
    religion: "Islam",
    email: "adebayomorufah@gmail.com",
    mobile_number: "09078044747",
    state: "Ogun",
    lga: "Abeokuta",
    class: "JSS 1",
    date_of_birth: "12-09-2001",
    residential_address: "Ayobo Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 36
  },
  {
    first_name: "Musa",
    last_name: "Jubril",
    middle_name: "",
    guardian_name: "Safiyyah",
    gender: "Male",
    religion: "Islam",
    email: "jewbreel1@gmail.com",
    mobile_number: "09078044747",
    state: "Kogi",
    lga: "Ankpa",
    class: "JSS 2",
    date_of_birth: "01-09-2001",
    residential_address: "Block 6, Flat 11, Arigbanla Millenium Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 37
  },
  {
    first_name: "Habeebah",
    last_name: "Lawal",
    middle_name: "Oyindamola",
    guardian_name: "Bolanle mudashir",
    gender: "Female",
    religion: "Islam",
    email: "damolabee5@gmail.com",
    mobile_number: "09078044747",
    state: "Kwara",
    lga: "Ilorin",
    class: "JSS 2",
    date_of_birth: "02-09-2001",
    residential_address: "Green roof Estate Phase 2 Ikorodu",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 38
  },
  {
    first_name: "Morufah",
    last_name: "Adebayo",
    middle_name: "Titilayo",
    guardian_name: "Adebayo",
    gender: "Female",
    religion: "Islam",
    email: "adebayomorufah@gmail.com",
    mobile_number: "09078044747",
    state: "Ogun",
    lga: "Abeokuta",
    class: "JSS 2",
    date_of_birth: "03-09-2001",
    residential_address: "Ayobo Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 39
  },
  {
    first_name: "Musa",
    last_name: "Jubril",
    middle_name: "",
    guardian_name: "Safiyyah",
    gender: "Male",
    religion: "Islam",
    email: "jewbreel1@gmail.com",
    mobile_number: "09078044747",
    state: "Kogi",
    lga: "Ankpa",
    class: "JSS 3",
    date_of_birth: "04-09-2001",
    residential_address: "Block 6, Flat 11, Arigbanla Millenium Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 40
  },
  {
    first_name: "Habeebah",
    last_name: "Lawal",
    middle_name: "Oyindamola",
    guardian_name: "Bolanle mudashir",
    gender: "Female",
    religion: "Islam",
    email: "damolabee5@gmail.com",
    mobile_number: "09078044747",
    state: "Kwara",
    lga: "Ilorin",
    class: "JSS 3",
    date_of_birth: "05-09-2001",
    residential_address: "Green roof Estate Phase 2 Ikorodu",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 41
  },
  {
    first_name: "Morufah",
    last_name: "Adebayo",
    middle_name: "Titilayo",
    guardian_name: "Adebayo",
    gender: "Female",
    religion: "Islam",
    email: "adebayomorufah@gmail.com",
    mobile_number: "09078044747",
    state: "Ogun",
    lga: "Abeokuta",
    class: "JSS 3",
    date_of_birth: "06-09-2001",
    residential_address: "Ayobo Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 42
  },
  {
    first_name: "Musa",
    last_name: "Jubril",
    middle_name: "",
    guardian_name: "Safiyyah",
    gender: "Male",
    religion: "Islam",
    email: "jewbreel1@gmail.com",
    mobile_number: "09078044747",
    state: "Kogi",
    lga: "Ankpa",
    class: "SSS 1 Science",
    date_of_birth: "07-09-2001",
    residential_address: "Block 6, Flat 11, Arigbanla Millenium Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 43
  },
  {
    first_name: "Habeebah",
    last_name: "Lawal",
    middle_name: "Oyindamola",
    guardian_name: "Bolanle mudashir",
    gender: "Female",
    religion: "Islam",
    email: "damolabee5@gmail.com",
    mobile_number: "09078044747",
    state: "Kwara",
    lga: "Ilorin",
    class: "SSS 1 Science",
    date_of_birth: "08-09-2001",
    residential_address: "Green roof Estate Phase 2 Ikorodu",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 44
  },
  {
    first_name: "Morufah",
    last_name: "Adebayo",
    middle_name: "Titilayo",
    guardian_name: "Adebayo",
    gender: "Female",
    religion: "Islam",
    email: "adebayomorufah@gmail.com",
    mobile_number: "09078044747",
    state: "Ogun",
    lga: "Abeokuta",
    class: "SSS 1 Science",
    date_of_birth: "09-09-2001",
    residential_address: "Ayobo Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 45
  },
  {
    first_name: "Musa",
    last_name: "Jubril",
    middle_name: "",
    guardian_name: "Safiyyah",
    gender: "Male",
    religion: "Islam",
    email: "jewbreel1@gmail.com",
    mobile_number: "09078044747",
    state: "Kogi",
    lga: "Ankpa",
    class: "SSS 1 Commercial",
    date_of_birth: "10-09-2001",
    residential_address: "Block 6, Flat 11, Arigbanla Millenium Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 46
  },
  {
    first_name: "Habeebah",
    last_name: "Lawal",
    middle_name: "Oyindamola",
    guardian_name: "Bolanle mudashir",
    gender: "Female",
    religion: "Islam",
    email: "damolabee5@gmail.com",
    mobile_number: "09078044747",
    state: "Kwara",
    lga: "Ilorin",
    class: "SSS 1 Commercial",
    date_of_birth: "11-09-2001",
    residential_address: "Green roof Estate Phase 2 Ikorodu",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 47
  },
  {
    first_name: "Morufah",
    last_name: "Adebayo",
    middle_name: "Titilayo",
    guardian_name: "Adebayo",
    gender: "Female",
    religion: "Islam",
    email: "adebayomorufah@gmail.com",
    mobile_number: "09078044747",
    state: "Ogun",
    lga: "Abeokuta",
    class: "SSS 1 Commercial",
    date_of_birth: "12-09-2001",
    residential_address: "Ayobo Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 48
  },
  {
    first_name: "Musa",
    last_name: "Jubril",
    middle_name: "",
    guardian_name: "Safiyyah",
    gender: "Male",
    religion: "Islam",
    email: "jewbreel1@gmail.com",
    mobile_number: "09078044747",
    state: "Kogi",
    lga: "Ankpa",
    class: "SSS 1 Art",
    date_of_birth: "01-09-2001",
    residential_address: "Block 6, Flat 11, Arigbanla Millenium Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 49
  },
  {
    first_name: "Habeebah",
    last_name: "Lawal",
    middle_name: "Oyindamola",
    guardian_name: "Bolanle mudashir",
    gender: "Female",
    religion: "Islam",
    email: "damolabee5@gmail.com",
    mobile_number: "09078044747",
    state: "Kwara",
    lga: "Ilorin",
    class: "SSS 1 Art",
    date_of_birth: "02-09-2001",
    residential_address: "Green roof Estate Phase 2 Ikorodu",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 50
  },
  {
    first_name: "Morufah",
    last_name: "Adebayo",
    middle_name: "Titilayo",
    guardian_name: "Adebayo",
    gender: "Female",
    religion: "Islam",
    email: "adebayomorufah@gmail.com",
    mobile_number: "09078044747",
    state: "Ogun",
    lga: "Abeokuta",
    class: "SSS 1 Art",
    date_of_birth: "03-09-2001",
    residential_address: "Ayobo Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 51
  },
  
  {
    first_name: "Musa",
    last_name: "Jubril",
    middle_name: "",
    guardian_name: "Safiyyah",
    gender: "Male",
    religion: "Islam",
    email: "jewbreel1@gmail.com",
    mobile_number: "09078044747",
    state: "Kogi",
    lga: "Ankpa",
    class: "SSS 2 Science",
    date_of_birth: "04-09-2001",
    residential_address: "Block 6, Flat 11, Arigbanla Millenium Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 52
  },
  {
    first_name: "Habeebah",
    last_name: "Lawal",
    middle_name: "Oyindamola",
    guardian_name: "Bolanle mudashir",
    gender: "Female",
    religion: "Islam",
    email: "damolabee5@gmail.com",
    mobile_number: "09078044747",
    state: "Kwara",
    lga: "Ilorin",
    class: "SSS 2 Science",
    date_of_birth: "05-09-2001",
    residential_address: "Green roof Estate Phase 2 Ikorodu",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 53
  },
  {
    first_name: "Morufah",
    last_name: "Adebayo",
    middle_name: "Titilayo",
    guardian_name: "Adebayo",
    gender: "Female",
    religion: "Islam",
    email: "adebayomorufah@gmail.com",
    mobile_number: "09078044747",
    state: "Ogun",
    lga: "Abeokuta",
    class: "SSS 2 Science",
    date_of_birth: "06-09-2001",
    residential_address: "Ayobo Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 54
  },
  {
    first_name: "Musa",
    last_name: "Jubril",
    middle_name: "",
    guardian_name: "Safiyyah",
    gender: "Male",
    religion: "Islam",
    email: "jewbreel1@gmail.com",
    mobile_number: "09078044747",
    state: "Kogi",
    lga: "Ankpa",
    class: "SSS 2 Commercial",
    date_of_birth: "07-09-2001",
    residential_address: "Block 6, Flat 11, Arigbanla Millenium Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 55
  },
  {
    first_name: "Habeebah",
    last_name: "Lawal",
    middle_name: "Oyindamola",
    guardian_name: "Bolanle mudashir",
    gender: "Female",
    religion: "Islam",
    email: "damolabee5@gmail.com",
    mobile_number: "09078044747",
    state: "Kwara",
    lga: "Ilorin",
    class: "SSS 2 Commercial",
    date_of_birth: "08-09-2001",
    residential_address: "Green roof Estate Phase 2 Ikorodu",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 56
  },
  {
    first_name: "Morufah",
    last_name: "Adebayo",
    middle_name: "Titilayo",
    guardian_name: "Adebayo",
    gender: "Female",
    religion: "Islam",
    email: "adebayomorufah@gmail.com",
    mobile_number: "09078044747",
    state: "Ogun",
    lga: "Abeokuta",
    class: "SSS 2 Commercial",
    date_of_birth: "09-09-2001",
    residential_address: "Ayobo Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 57
  },
  {
    first_name: "Musa",
    last_name: "Jubril",
    middle_name: "",
    guardian_name: "Safiyyah",
    gender: "Male",
    religion: "Islam",
    email: "jewbreel1@gmail.com",
    mobile_number: "09078044747",
    state: "Kogi",
    lga: "Ankpa",
    class: "SSS 2 Art",
    date_of_birth: "10-09-2001",
    residential_address: "Block 6, Flat 11, Arigbanla Millenium Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 58
  },
  {
    first_name: "Habeebah",
    last_name: "Lawal",
    middle_name: "Oyindamola",
    guardian_name: "Bolanle mudashir",
    gender: "Female",
    religion: "Islam",
    email: "damolabee5@gmail.com",
    mobile_number: "09078044747",
    state: "Kwara",
    lga: "Ilorin",
    class: "SSS 2 Art",
    date_of_birth: "11-09-2001",
    residential_address: "Green roof Estate Phase 2 Ikorodu",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 59
  },
  {
    first_name: "Morufah",
    last_name: "Adebayo",
    middle_name: "Titilayo",
    guardian_name: "Adebayo",
    gender: "Female",
    religion: "Islam",
    email: "adebayomorufah@gmail.com",
    mobile_number: "09078044747",
    state: "Ogun",
    lga: "Abeokuta",
    class: "SSS 2 Art",
    date_of_birth: "12-09-2001",
    residential_address: "Ayobo Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 60
  },
  
  {
    first_name: "Musa",
    last_name: "Jubril",
    middle_name: "",
    guardian_name: "Safiyyah",
    gender: "Male",
    religion: "Islam",
    email: "jewbreel1@gmail.com",
    mobile_number: "09078044747",
    state: "Kogi",
    lga: "Ankpa",
    class: "SSS 3 Science",
    date_of_birth: "01-09-2001",
    residential_address: "Block 6, Flat 11, Arigbanla Millenium Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 61
  },
  {
    first_name: "Habeebah",
    last_name: "Lawal",
    middle_name: "Oyindamola",
    guardian_name: "Bolanle mudashir",
    gender: "Female",
    religion: "Islam",
    email: "damolabee5@gmail.com",
    mobile_number: "09078044747",
    state: "Kwara",
    lga: "Ilorin",
    class: "SSS 3 Science",
    date_of_birth: "02-09-2001",
    residential_address: "Green roof Estate Phase 2 Ikorodu",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 62
  },
  {
    first_name: "Morufah",
    last_name: "Adebayo",
    middle_name: "Titilayo",
    guardian_name: "Adebayo",
    gender: "Female",
    religion: "Islam",
    email: "adebayomorufah@gmail.com",
    mobile_number: "09078044747",
    state: "Ogun",
    lga: "Abeokuta",
    class: "SSS 3 Science",
    date_of_birth: "03-09-2001",
    residential_address: "Ayobo Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 63
  },
  {
    first_name: "Musa",
    last_name: "Jubril",
    middle_name: "",
    guardian_name: "Safiyyah",
    gender: "Male",
    religion: "Islam",
    email: "jewbreel1@gmail.com",
    mobile_number: "09078044747",
    state: "Kogi",
    lga: "Ankpa",
    class: "SSS 3 Commercial",
    date_of_birth: "04-09-2001",
    residential_address: "Block 6, Flat 11, Arigbanla Millenium Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 64
  },
  {
    first_name: "Habeebah",
    last_name: "Lawal",
    middle_name: "Oyindamola",
    guardian_name: "Bolanle mudashir",
    gender: "Female",
    religion: "Islam",
    email: "damolabee5@gmail.com",
    mobile_number: "09078044747",
    state: "Kwara",
    lga: "Ilorin",
    class: "SSS 3 Commercial",
    date_of_birth: "05-09-2001",
    residential_address: "Green roof Estate Phase 2 Ikorodu",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 65
  },
  {
    first_name: "Morufah",
    last_name: "Adebayo",
    middle_name: "Titilayo",
    guardian_name: "Adebayo",
    gender: "Female",
    religion: "Islam",
    email: "adebayomorufah@gmail.com",
    mobile_number: "09078044747",
    state: "Ogun",
    lga: "Abeokuta",
    class: "SSS 3 Commercial",
    date_of_birth: "06-09-2001",
    residential_address: "Ayobo Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 66
  },
  {
    first_name: "Musa",
    last_name: "Jubril",
    middle_name: "",
    guardian_name: "Safiyyah",
    gender: "Male",
    religion: "Islam",
    email: "jewbreel1@gmail.com",
    mobile_number: "09078044747",
    state: "Kogi",
    lga: "Ankpa",
    class: "SSS 3 Art",
    date_of_birth: "07-09-2001",
    residential_address: "Block 6, Flat 11, Arigbanla Millenium Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 67
  },
  {
    first_name: "Habeebah",
    last_name: "Lawal",
    middle_name: "Oyindamola",
    guardian_name: "Bolanle mudashir",
    gender: "Female",
    religion: "Islam",
    email: "damolabee5@gmail.com",
    mobile_number: "09078044747",
    state: "Kwara",
    lga: "Ilorin",
    class: "SSS 3 Art",
    date_of_birth: "08-09-2001",
    residential_address: "Green roof Estate Phase 2 Ikorodu",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 68
  },
  {
    first_name: "Morufah",
    last_name: "Adebayo",
    middle_name: "Titilayo",
    guardian_name: "Adebayo",
    gender: "Female",
    religion: "Islam",
    email: "adebayomorufah@gmail.com",
    mobile_number: "09078044747",
    state: "Ogun",
    lga: "Abeokuta",
    class: "SSS 3 Art",
    date_of_birth: "09-09-2001",
    residential_address: "Ayobo Estate",
    outstanding_payment: 0,
    new_payment: 0,
    age: 20,
    id: 69
  },
  
];
